import React from "react";
import "./style.css";

const Footer = () => {
    return (
        <footer className="footer">
            <ul className="footer__soc">
                <li className="footer__item">
                    <a className="footer__link" href="https://t.me/alienba6y">
                        <span className="visually-hidden">telegram</span>
                        <svg className="footer__icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1124_61)">
                                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#039BE5" />
                                <path d="M9.15174 19.567L28.4351 12.132C29.3301 11.8087 30.1117 12.3503 29.8217 13.7037L29.8234 13.702L26.5401 29.1703C26.2967 30.267 25.6451 30.5337 24.7334 30.017L19.7334 26.332L17.3217 28.6553C17.0551 28.922 16.8301 29.147 16.3134 29.147L16.6684 24.0587L25.9351 15.687C26.3384 15.332 25.8451 15.132 25.3134 15.4853L13.8617 22.6953L8.92507 21.1553C7.8534 20.8153 7.83007 20.0837 9.15174 19.567V19.567Z" fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1124_61">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </li>
                <li className="footer__item">
                    <a className="footer__link" href="https://github.com/whosemivan">
                        <span className="visually-hidden">gitbub</span>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9975 0.416016C8.955 0.416016 0 9.40601 0 20.496C0 29.366 5.73 36.891 13.68 39.5485C14.68 39.7335 15.045 39.1135 15.045 38.581C15.045 38.1035 15.0275 36.841 15.0175 35.166C9.455 36.3785 8.28 32.4735 8.28 32.4735C7.3725 30.1535 6.06 29.536 6.06 29.536C4.2425 28.291 6.195 28.316 6.195 28.316C8.2025 28.4585 9.2575 30.386 9.2575 30.386C11.0425 33.4535 13.94 32.5685 15.08 32.0535C15.26 30.756 15.7775 29.871 16.35 29.3685C11.91 28.861 7.24 27.1385 7.24 19.446C7.24 17.2535 8.02 15.461 9.3 14.056C9.0925 13.5485 8.4075 11.506 9.495 8.74352C9.495 8.74352 11.175 8.20352 14.995 10.801C16.59 10.356 18.3 10.1335 20.0025 10.126C21.7 10.136 23.4125 10.356 25.01 10.8035C28.8275 8.20602 30.505 8.74602 30.505 8.74602C31.595 11.511 30.91 13.551 30.705 14.0585C31.9875 15.4635 32.76 17.256 32.76 19.4485C32.76 27.161 28.085 28.8585 23.63 29.356C24.3475 29.976 24.9875 31.201 24.9875 33.0735C24.9875 35.7585 24.9625 37.9235 24.9625 38.581C24.9625 39.1185 25.3225 39.7435 26.3375 39.546C34.275 36.886 40 29.3635 40 20.496C40 9.40601 31.045 0.416016 19.9975 0.416016Z" fill="black" />
                        </svg>
                    </a>
                </li>
                <li className="footer__item">
                    <a className="footer__link" href="https://www.linkedin.com/in/ivansemyonenko/">
                        <span className="visually-hidden">linkedin</span>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1124_68)">
                                <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#007AB9" />
                                <path d="M31.9498 21.6085V29.8541H27.1693V22.1611C27.1693 20.2295 26.479 18.9104 24.7481 18.9104C23.4272 18.9104 22.6425 19.7984 22.296 20.6584C22.1701 20.9657 22.1377 21.3924 22.1377 21.8235V29.8537H17.3568C17.3568 29.8537 17.421 16.8244 17.3568 15.4757H22.1381V17.5132C22.1284 17.5292 22.1149 17.5449 22.1063 17.5602H22.1381V17.5132C22.7734 16.5356 23.9064 15.138 26.4466 15.138C29.5918 15.138 31.9498 17.193 31.9498 21.6085ZM12.3555 8.54492C10.7202 8.54492 9.65027 9.6184 9.65027 11.0288C9.65027 12.4092 10.6892 13.5137 12.2928 13.5137H12.3238C13.9912 13.5137 15.028 12.4092 15.028 11.0288C14.9963 9.6184 13.9912 8.54492 12.3555 8.54492ZM9.93441 29.8541H14.7136V15.4757H9.93441V29.8541Z" fill="#F1F2F2" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1124_68">
                                    <rect width="40" height="40" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                </li>
            </ul>
        </footer>
    );
};

export default Footer;